import i18n from '@/i18n'

export default [
    {
        path: '/dashboard',
        name: 'dashboard.index',
        component: () => import('../views/dashboard/Index.vue'),
        meta: { auth: true, visible: true, icon: 'mdi-timer-outline', text: i18n.t('scoliotracker.dashboard') }
    },

    {
        path: '/dashboard/analytics',
        name: 'dashboard.analytics.index',
        component: () => import('../views/dashboard/analytics/Index.vue'),
        meta: {
            auth: true,
            visible: true,
            icon: 'mdi-chart-line',
            text: i18n.t('scoliotracker.analytics'),
        }
    },
    {
        path: '/dashboard/profile',
        name: 'dashboard.profile',
        component: () => import('../views/dashboard/Profile.vue'),
        meta: { auth: true, visible: false }
    },
    {
        path: '/dashboard/workouts',
        name: 'dashboard.workouts.index',
        component: () => import('../views/dashboard/workouts/Index.vue'),
        meta: {
            auth: true, visible: true, icon: 'mdi-arm-flex',
            text: i18n.t('scoliotracker.workout'),
        }
    },
    {
        path: '/dashboard/donate',
        name: 'dashboard.donate.index',
        component: () => import('../views/dashboard/donate/Index.vue'),
        meta: {
            auth: true, visible: true, icon: 'mdi-heart',
            text: i18n.t('scoliotracker.donate'),
        }
    },
    {
        path: '/dashboard/xrays',
        name: 'dashboard.xrays.index',
        component: () => import('../views/dashboard/xrays/Index.vue'),
        meta: {
            auth: true, visible: true, icon: 'mdi-radiology-box',
            text: i18n.t('scoliotracker.xrays'),
        }
    },
    {
        path: '/dashboard/billing',
        name: 'dashboard.billing',
        component: () => import('../views/dashboard/billing/Index.vue'),
        meta: { auth: true, visible: false }
    },
    {
        path: '/dashboard/billing/select-plan',
        name: 'dashboard.billing.select-plan',
        component: () => import('../views/dashboard/billing/SelectPlan.vue'),
        meta: { auth: true, visible: false }
    },
    {
        path: '/dashboard/billing/checkout',
        name: 'dashboard.billing.checkout',
        component: () => import('../views/dashboard/billing/Checkout.vue'),
        meta: { auth: true, visible: false }
    },
]
