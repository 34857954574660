<template functional>
    <a class="logo d-flex align-center" href="/">
        <img :src="require('@/assets/icons/logo.png')" height="42" alt="logo" />
    </a>
</template>

<script>
export default {
    name: "Logo",
};
</script>

<style lang="scss" scoped>
</style>
